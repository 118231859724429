@import "fonts";
@import "custom-bootstrap";
@import "helpers";

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-active-color: #fff;
    --bs-btn-disabled-color: #fff;
    --bs-btn-hover-color: #fff;
}
.btn-outline-primary {
    --bs-btn-hover-color: #fff;
    --bs-btn-active-color: #fff;
}

.btn-bicolor {
    --bs-btn-color: #fff;
    --bs-btn-active-color: #fff;
    --bs-btn-disabled-color: #fff;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--bs-secondary);
    --bs-btn-hover-border-color: var(--bs-secondary);
}
.btn-outline-bicolor {
    --bs-btn-hover-color: #fff;
    --bs-btn-active-color: #fff;
}

.btn-primary-light {
    --bs-btn-color: #fff;
    --bs-btn-hover-color: #fff;
    --bs-btn-active-color: #fff;
    --bs-btn-disabled-color: #fff;
}
.btn-outline-primary-light {
    --bs-btn-hover-color: #fff;
    --bs-btn-active-color: #fff;
}
.btn-secondary {
    --bs-btn-color: #fff;
    --bs-btn-hover-color: #fff;
    --bs-btn-active-color: #fff;
    --bs-btn-disabled-color: #fff;
}
.btn-outline-secondary {
    --bs-btn-hover-color: #fff;
    --bs-btn-active-color: #fff;
}

$accordion-button-active-bg: $body-bg;

#app {
    font-family: Poppins;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    position: absolute;
    top: 92px;
    right: 0;
    left: 0;
    bottom: 0;

    @include media-breakpoint-down(md) {
        top: 82px;
    }

    &:before {
        content: "";
        display: block;
        position: fixed;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -10;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0.05;
    }

    .navbar {
        --bs-navbar-active-color: $black;

        position: fixed;
        right: 0;
        left: 0;
        z-index: 1030;
        @extend .fixed-top;
        background-color: $white !important;

        .nav-link {
            cursor: pointer;
            font-family: MerriweatherSans;
            font-weight: 400;
            font-size: 1rem;
            display: flex;
            flex-direction: column;
            align-items: center;

            > i {
                background: $mygradient;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                margin-bottom: 0.25rem;
            }

            &:hover > * {
                // color: $primary !important;
            }

            @include media-breakpoint-down(md) {
                flex-direction: row;

                > i {
                    margin-bottom: 0;
                    margin-right: 0.5rem;
                }
            }
        }

        .navbar-brand {
            padding: 0;
            margin-top: -5px;
            margin-bottom: -5px;

            img {
                max-height: 70px;
                max-width: 150px;
            }

            @include media-breakpoint-down(md) {
                margin-right: 0;

                img {
                    max-height: 50px;
                    max-width: 100px;
                }
            }
        }
    }

    footer {
        background: $mygradient;
        color: white;

        .col {
            text-align: center;
        }

        a {
            color: white;
        }
    }
}

h1,
h2 {
    &.title-section {
        font-family: MerriweatherSans;
        background: $mygradient;
        color: $white;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
        @extend .py-3;
    }
}

h1 {
    font-family: MerriweatherSans;
    font-weight: bold;
}

h2,
h3 {
    font-family: Poppins;
    font-weight: normal;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.bg-design {
    background: $mygradient;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.separation {
    height: 2px;
    background: linear-gradient(to right, $myblue 26%, $mygreen);
    width: 50%;
    margin: 3rem auto;
}

.text-container {
    p:last-child {
        margin-bottom: 0;
    }
}

.home {
    .cab {
        min-width: 60%;
        align-self: flex-end;

        .box-rebours {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
        }
        .box_jour,
        .box_heure,
        .box_minute,
        .box_seconde {
            padding: 10px;
        }
        #jour,
        #heure,
        #minute,
        #seconde {
            background: black;
            padding: 10px 20px;
            color: white;
            font-size: 3rem;
            font-weight: bold;
        }
        #jour_label,
        #heure_label,
        #minute_label,
        #seconde_label {
            font-size: 1.5rem;
        }
    }

    .phonemode {
        > .row {
            background: $mygradient;
        }
        .countdown__block {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: transparent !important;
            color: white !important;
            padding: 1rem;
            margin: 0 2px;
        }

        .countdown__digit {
            font-size: 6vw;
            line-height: 1;
            margin-bottom: 2px;
        }

        .countdown__text {
            display: inline-block;
            text-transform: uppercase;
            font-size: 12px;
            color: white !important;
        }
    }

    &__image {
        position: relative;
        min-height: 500px;
        background-color: rgba($color: $gray-900, $alpha: 0.5);

        &::before {
            content: "";
            position: absolute;
            z-index: -1;
            background-image: url(../img/home.jpg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            @include media-breakpoint-up(md) {
                background-size: cover;
            }
        }
    }

    &__title {
        width: 90%;
        color: #fff;
        text-shadow: 1px 1px $secondary;
        font-family: MerriweatherSans;
        font-weight: bold;
        font-size: 64px;
        align-self: center;
        line-height: 1.5;

        > span:first-child {
            font-size: 70px !important;
        }

        > *:not(span:first-child) {
            letter-spacing: 3px;
        }

        &__underline {
            background: linear-gradient(to right, $myblue 26%, $mygreen);
            height: 6px;
        }

        @include media-breakpoint-down(lg) {
            font-size: 55px;
        }

        @include media-breakpoint-down(md) {
            font-size: 45px;
        }

        @include media-breakpoint-down(sm) {
            font-size: 35px;
        }
    }

    &__description {
    }
}

.programme {
    &--table {
        .time {
            width: 120px;
            white-space: nowrap;
            vertical-align: top;
            padding-left: 0.5rem;
            padding-right: 1.2rem;
            color: $primary;
        }

        tr.border-bottom {
            border-bottom-color: $secondary !important;

            td {
                padding-bottom: 1.5rem;
            }

            & + tr {
                td {
                    padding-top: 1.5rem;
                }
            }
        }

        tr.border-top {
            border-top-color: $secondary !important;

            td {
                padding-top: 1.5rem;
            }

            & + tr {
                td {
                    padding-bottom: 1.5rem;
                }
            }
        }
    }

    .accordion {
        --bs-accordion-active-bg: var(--bs-body-bg);
        --bs-accordion-border-color: var(--bs-primary-xlight);
        --bs-accordion-btn-focus-border-color: none;
        --bs-accordion-btn-focus-box-shadow: none;
    }
}

.acces {
    .infos-content {
        &::before {
            content: "";
            position: absolute;
            z-index: -1;
            background-image: url(../img/photo-noveka.png);
            background-repeat: no-repeat;
            background-position: top;
            background-size: cover;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            @include media-breakpoint-up(md) {
                background-size: cover;
            }
        }

        background-color: rgba($color: $gray-900, $alpha: 0.5);

        i {
            background: $mygradient;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        a {
            color: $white;
        }
    }
}

.intervenants {
    &__item {
        * {
            white-space: nowrap !important;
        }
    }
}

.yt-video {
    max-width: 100%;
    width: 800px;
    height: 300px;

    @media only screen and (min-width: 801px) {
        height: 500px;
    }
}
