.poppins {
    font-family: Poppins;
}
.merriweather {
    font-family: MerriweatherSans;
}

.shadow-white {
    text-shadow: 0.5px 0.5px white;
}

.d-flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.cursor-pointer {
    cursor: pointer;
}

.border-top-lg {
    border-top-width: 3px !important;
}
.border-bottom-lg {
    border-bottom-width: 3px !important;
}
.border-left-lg {
    border-left-width: 3px !important;
}
.border-right-lg {
    border-right-width: 3px !important;
}

@each $color, $value in $theme-colors {
    .text-hover-#{$color}:hover {
        color: $value !important;
        cursor: pointer;
    }
}

.text-hover-underline {
    &:hover {
        text-decoration: underline !important;
    }
}

.text-justify {
    text-align: justify;
}

.alert-success {
    --bs-alert-bg: #309365;
}
.alert-danger {
    --bs-alert-bg: #e04958;
}
