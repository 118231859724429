$mygreen: #86bc25;
$myblue: #00adc4;

$primary: $mygreen;
$primary-light: shift-color($mygreen, -20%);
$primary-xlight: shift-color($mygreen, -40%);
$primary-dark: shift-color($mygreen, 20%);
$primary-xdark: shift-color($mygreen, 40%);
$secondary: $myblue;

$mygradient: linear-gradient(to right, $myblue 26%, $mygreen);

// navbar
$navbar-light-color: rgba($primary, 0.55) !default;
$navbar-light-hover-color: rgba($primary, 0.7) !default;
$navbar-light-active-color: $primary !default;
$navbar-light-disabled-color: rgba($primary, 0.3) !default;
$navbar-light-toggler-border-color: rgba($primary, 0.1) !default;

$nav-link-font-size: 1.1rem;
$link-decoration: none;
$enable-negative-margins: true;