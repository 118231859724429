@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 900;
    src: url("fonts/poppins/Poppins-900-Black.ttf ");
}

@font-face {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 900;
    src: url("fonts/poppins/Poppins-900-BlackItalic.ttf ");
}

@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 800;
    src: url("fonts/poppins/Poppins-800-ExtraBold.ttf ");
}

@font-face {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 800;
    src: url("fonts/poppins/Poppins-800-ExtraBoldItalic.ttf ");
}

@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    src: url("fonts/poppins/Poppins-700-Bold.ttf ");
}

@font-face {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 700;
    src: url("fonts/poppins/Poppins-700-BoldItalic.ttf ");
}

@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    src: url("fonts/poppins/Poppins-600-SemiBold.ttf ");
}

@font-face {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 600;
    src: url("fonts/poppins/Poppins-600-SemiBoldItalic.ttf ");
}

@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    src: url("fonts/poppins/Poppins-500-Medium.ttf ");
}

@font-face {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 500;
    src: url("fonts/poppins/Poppins-500-MediumItalic.ttf ");
}

@font-face {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 400;
    src: url("fonts/poppins/Poppins-400-Italic.ttf ");
}

@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    src: url("fonts/poppins/Poppins-400-Regular.ttf ");
}

@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    src: url("fonts/poppins/Poppins-300-Light.ttf ");
}

@font-face {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 300;
    src: url("fonts/poppins/Poppins-300-LightItalic.ttf ");
}

@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 200;
    src: url("fonts/poppins/Poppins-200-ExtraLight.ttf ");
}

@font-face {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 200;
    src: url("fonts/poppins/Poppins-200-ExtraLightItalic.ttf ");
}

@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 100;
    src: url("fonts/poppins/Poppins-100-Thin.ttf ");
}

@font-face {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 100;
    src: url("fonts/poppins/Poppins-100-ThinItalic.ttf");
}
