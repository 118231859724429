
.intervenant-item {
    &__photo {
        overflow: hidden;
        width: 150px;
        height: 150px;
        min-width: 150px;
        min-height: 150px;

        img {
            object-fit: cover;
            object-position: center;
        }
    }
    &__name {
        margin-top: 1rem;
    }
    &__job {
    }
}
