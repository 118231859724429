@font-face {
    font-family: MerriweatherSans;
    font-style: normal;
    src: url("fonts/merriweather-sans/MerriweatherSans-VariableFont_wght.ttf");
}

@font-face {
    font-family: MerriweatherSans;
    font-style: italic;
    src: url("fonts/merriweather-sans/MerriweatherSans-Italic-VariableFont_wght.ttf");
}
